@import '../../styles/includes.scss';

.TextBlock {
    background-color: $colorWhiteBackground;

    &__Grid {
        @extend %container;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }

    &__Title {
        @extend %h2;
        margin-bottom: $spacingXl;
        max-width: $maxWidthText;

        @include media(l) {
            margin-bottom: $spacingL * 2;
        }
    }

    &__MetaContainer {
        display: flex;
        align-items: center;
        margin-bottom: $spacingM * 3;

        @include media(m) {
            margin-bottom: $spacingXl * 2;
        }
    }

    &__Preamble {
        @extend %preamble;
        color: $colorBlack750;
        max-width: $maxWidthText;
        margin-bottom: $spacingXl;

        @include media(m) {
            margin-bottom: $spacingM * 3;
        }
    }

    &__RichTextWrapper {
        max-width: $maxWidthText;
        margin-bottom: $spacingL * 2;
        padding: 0;

        & > div {
            padding: 0;
        }
    }

    &__ButtonWrapper {
        margin-top: 3.2rem;
        margin-bottom: $spacingL * 2;
    }

    &__ContactWrapper {
        padding-top: 0.8rem;
        margin-bottom: $spacingL * 2;
    }

    &__LinksSection {
        margin-bottom: 3.6rem;
    }

    &__LinksWrapper {
        border-top: 1px solid $colorPink;
        width: 66%;
        padding-top: 20px;
    }

    &__LinksHeading {
        @extend %h4;
        margin-bottom: 10px;
    }

    &__LinksListItem {
        margin-bottom: $spacingXs;
    }

    &__Link {
        display: inline-block;
        @extend %links;
    }
}
